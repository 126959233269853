import {m as motion} from 'framer-motion';
import Image from 'next/image';
import styled from 'styled-components';

import {AsideAnimation} from 'UI/animations/login';

// eslint-disable-next-line import/extensions
import Logon from 'UI/assets/images/LoginAside.jpg';

/**
 * Renders the login aside component.
 * This component is a visual element displayed alongside the login form. It features an image related to logging in,
 * using the `StyledImage` component for optimized loading and display. The image uses a placeholder blur effect for a smooth
 * user experience while the image loads. The `Aside` component is animated using the `AsideAnimation` to enhance the visual appeal.
 * The `sizes` attribute is set to "50vw" to ensure the image occupies half of the viewport width, making it responsive to different screen sizes.
 *
 * @returns The login aside component containing a stylized image.
 *
 * @example
 * ```tsx
 * <LoginAside />
 * ```
 */
const LoginAside = () => (
    <Aside variants={AsideAnimation}>
        <StyledImage
            alt="Login"
            placeholder="blur"
            sizes="50vw"
            src={Logon}
            fill
            priority
        />
    </Aside>
);

LoginAside.displayName = 'LoginAside';
LoginAside.defaultProps = {testId: 'LoginAside'};

export {LoginAside};

const Aside = styled(motion.aside)`
    flex: 0 0 42%;
    max-width: 1920px;
    position: relative;
`;

const StyledImage = styled(Image)`
    object-fit: cover;
    object-position: 75% 0%;
`;