import {Container, Spacer} from '@nfq/react-grid';
import {m as motion} from 'framer-motion';
import styled from 'styled-components';

import {H2, P} from 'UI/components/layout/Text';

import {ContentAnimation, HeaderTransition, HeadlineTransition, LogoTransition} from 'UI/animations/login';
import {OpacityAnimation} from 'UI/animations/shared';
import {LoginAside} from 'UI/layouts/login/LoginAside';

import {Logo} from 'Images/icons';
import {iconAsBackground} from 'UI/utils/helpers';

import type {WithChildren} from 'types/global';

interface ComponentProps {
    /** The test id. */
    testId: string;
}

/**
 * Represents the login layout component for the application.
 * This component serves as the primary layout for the login page, providing a structured and consistent look and feel.
 * It includes a `LoginWrapper` to encapsulate all content, with a `LayoutWrap` further organizing the layout into distinct sections.
 * The `Content` component, animated with `ContentAnimation`, contains the login form and related elements. The `Container` acts as a fluid section
 * for the form and branding elements. The `LogoWrapper` displays the application's logo and title, animated for a dynamic appearance.
 * The `Spacer` component adds vertical spacing for visual separation. The `MotionH2` displays the welcome message with an opacity animation for a smooth transition.
 * The `LoginAside` component provides an additional visual element to the layout, typically for branding or decorative purposes.
 * Lastly, a `Wrapper` at the bottom includes layout and transition configurations for the header section of the login page.
 *
 * @param props          The component props.
 * @param props.children The content to be displayed within the login layout, typically the login form.
 * @param props.testId   A unique identifier for testing purposes.
 * @returns The login layout component with structured sections and animations.
 *
 * @example
 * ```tsx
 * <Login testId="loginTestId">
 *   <LoginForm />
 * </Login>
 * ```
 */
const Login = ({children, testId}: WithChildren<ComponentProps>) => (
    <LoginWrapper data-cy={testId}>
        <LayoutWrap>
            <Content variants={ContentAnimation}>
                <Container as="section" isFluid>
                    <LogoWrapper>
                        <MotionLogo layoutId="Logo" transition={LogoTransition} />
                        <Spacer x={4} isInline isNotStretching />
                        <motion.div layoutId="LogoText" transition={LogoTransition}>
                            <P $isLight>Brand Guardianship</P>
                        </motion.div>
                    </LogoWrapper>
                    <Spacer y={8} isNotStretching />
                    <motion.div transition={HeadlineTransition}>
                        <MotionH2 variants={OpacityAnimation} $isLight layout>Willkommen zum</MotionH2>
                    </motion.div>
                    {children}
                </Container>
            </Content>
            <LoginAside />
        </LayoutWrap>
        <Wrapper layoutId="Header" transition={HeaderTransition} />
    </LoginWrapper>
);

Login.displayName = 'Login';
Login.defaultProps = {testId: 'Login'};

export {Login};

const LoginWrapper = styled.div`
    height: 100%;
    width: 100%;
`;

const Wrapper = styled(motion.div)`
    background-color: ${({theme}) => theme.colors.primaryBackgroundColor};
    height: 100vh;
    width: 100%;
`;

const LayoutWrap = styled.div`
    align-items: stretch;
    bottom: 0;
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
`;

const Content = styled(motion.main)`
    display: flex;
    flex: 1 1 58%;
    flex-direction: column;
    justify-content: center;
`;

const LogoWrapper = styled.div`
    align-items: center;
    display: flex;
`;

const MotionLogo = styled(motion.div)`
    background-image: url(${iconAsBackground(Logo)});
    background-repeat: no-repeat;
    background-size: contain;
    height: 32px;
    width: 32px;
`;

const MotionH2 = motion(H2);