import {useState} from 'react';

import {useRouter} from 'next/router';

import LoginService from 'Application/services/LoginService';

/**
 * Custom hook for handling login functionality.
 *
 * This hook utilizes the 'useRouter' hook from 'next/router' and the 'useState' hook to handle the login process.
 * It provides a 'handleLogin' function that performs the authentication and login using the 'LoginService.authenticate'
 * and 'LoginService.login' methods. It also tracks the error state during the login process.
 *
 * @returns An object containing the error state and the 'handleLogin' function.
 *
 * @example
 * ```tsx
 * const LoginForm = () => {
 *   const { error, handleLogin } = useLogin();
 *
 *   const handleSubmit = (e: React.FormEvent) => {
 *     e.preventDefault();
 *     handleLogin();
 *   };
 *
 *   return (
 *     <form onSubmit={handleSubmit}>
 *       <input type="text" placeholder="Username" />
 *       <input type="password" placeholder="Password" />
 *       {error && <div>Error occurred during login.</div>}
 *       <button type="submit">Login</button>
 *     </form>
 *   );
 * };
 * ```
 */
export const useLogin = () => {
    const router = useRouter();
    const [error, setError] = useState(false);

    /**
     * Function for handling the login process.
     *
     * This function sets the 'error' state to false, then tries to authenticate the user using the
     * 'LoginService.authenticate' method. If successful, it performs the login using the 'LoginService.login'
     * method. It retrieves the 'redirectUrl' from the router query parameters and pushes the user to that URL.
     * If any error occurs during the process, the 'error' state is set to true.
     */
    const handleLogin = async () => {
        setError(false);

        try {
            const token = await LoginService.authenticate();

            await LoginService.login(token);

            const url = router.query.redirect as string | undefined ?? '/';

            await router.push(url);
        } catch {
            setError(true);
        }
    };

    return {
        error,
        handleLogin
    };
};